@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-SemiBold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Spartan";
  src: url("../assets/fonts/Spartan-Black.ttf");
  font-weight: 900;
}