/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import "@ionic/angular/css/palettes/dark.system.css";

:root {
    --safe-area-inset-customtop: 0px; // Default value
    --safe-area-inset-custombottom: 0px;
}


* {
    font-family: Spartan !important;
    line-height: 18px;
    font-style: normal;
    line-height: 140%;
    letter-spacing: -0.01em;
    --padding-start: 0;
    --padding-end: 0;
}

ion-textarea {
    --padding-top: 0;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

.updateModal {
    --background: transparent !important;
    --backdrop-opacity: .8
}

// ::-webkit-scrollbar {
//     display: none;
// }

.headlinetitle {
    @media only screen and (max-width: 767px) {
        color: #1E1725;
        font-weight: 600;
        margin-bottom: 20px;
        width: 100%;
        text-align: start;
        font-style: normal;
        font-size: 16px;
        line-height: 140%;
        /* identical to box height, or 31px */
        letter-spacing: -0.01em;
    }

    @media only screen and (min-width: 767px) {
        color: #1E1725;
        font-weight: 600;
        margin-bottom: 20px;
        width: 100%;
        text-align: start;
        font-style: normal;
        font-size: 16px;
        line-height: 140%;
        /* identical to box height, or 31px */
        letter-spacing: -0.01em;
    }

}

.inputtitle {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #1E1725;
}

.input {
    --placeholder-color: #7F778E !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    // line-height: 140% !important;
    letter-spacing: -0.01em !important;
    color: #1E1725 !important;
    // width: 100% !important;
    text-align: start !important;
}

.selectinput {
    color: #1E1725 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: -0.01em !important;
    --placeholder-color: #7F778E !important;
}

.inputBorder {
    padding: 0 8px;
    height: 48px;
    border: 1px solid #E4E0EB;
    border-radius: 8px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.textareaBorder {
    padding: 0 8px 0 8px;
    border: 1px solid #E4E0EB;
    border-radius: 8px;
    width: 100%;
    justify-content: space-between;
    display: flex;
}

.native-textarea.sc-ion-textarea-md {
    padding: 0 !important;
}


.border {
    margin-bottom: 20px;
}

.item-has-focus {
    color: #9933CC !important;
}

.error {
    margin-top: 4px;
    font-size: 12px;
    text-align: start;
    color: #ed3d1a;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 140% !important;
    letter-spacing: -0.01em !important;
}

.header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: center;
    letter-spacing: -0.01em;

    /* Text Colors/Headline Title */

    color: #1E1725;
}

::ng-deep.toolbar {
    display: none !important;
}

.inputbasic {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.01em;
}

ion-slides {

    .swiper-slide,
    .swiper-pagination {
        --bullet-background: #7F778E;
        --bullet-background-active: #33C9AE;
    }
}

.swiper-pagination-bullet {
    width: 6px !important;
    height: 6px !important;
}

.swiper-pagination-bullet-active {
    width: 16px !important;
    border-radius: 10px !important;
    height: 6px !important;
}

::ng-deep.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border: 1px solid #ccc;
    box-shadow: none;
}

::ng-deep.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: none !important;
    border-style: none !important;
    border-width: 0 !important;
}

::ng-deep.ng-select .ng-arrow-wrapper {
    background-image: url('/assets/icon/chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    margin-top: 2px;
}

::ng-deep.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #1E1725 !important;
    background-color: #ebf5ff;
}

ion-modal#example-modal {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.giftModal {
    --background: #00000080;
    --width: 100vw;
    --height: 100vh;
}

::ng-deep.ng-value-container .ng-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}